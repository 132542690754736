import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { IEnvironment } from '../app/interfaces/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
export const environment: IEnvironment = {
  production: true,
  // QA
  appConfig: {
    baseURLAdmin: 'https://api.cpm.pilot.care.analog.com/',
    baseURLVCM: '  https://api.mdh.pilot.care.analog.com/',
    baseURLToGenerateToken: 'https://api.aaa.pilot.care.analog.com/',
    baseURLExternal: 'https://go.pilot.care.analog.com/',
    baseURLTrends: 'https://api-trends.cpm.pilot.care.analog.com/',
    environment: 'v1/',
    environmentVCM: 'patients/',
    environmentVCMDefault: 'defaults/'
  },
  authConfig: {
    auth: {
      clientId: 'a03e3e87-7571-4b5a-b108-16e9f4aaa790',
      authority:
        'https://id.care.analog.com/analogdhcb2c.onmicrosoft.com/b2c_1a_adi_signuporsignin/',
      redirectUri: 'https://cpm.pilot.care.analog.com',
      knownAuthorities: ['id.care.analog.com']
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE
    },
    system: {
      /* loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      } */
    }
  },
  authRequest: { scopes: ['offline_access', 'openid'] },
  isRightClickAllowed: true
};
